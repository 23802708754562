import React from 'react'
import { Link } from 'gatsby'
// import { Button, Box, Heading, Text } from 'theme-ui'
import { Button, Box, Heading } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    {/* <Heading variant='h1'>
      Hi, I'm <Text sx={styles.author}>Jane Smith</Text>.
    </Heading> */}
    <Heading variant='h1' sx={styles.occupation}>
      Mondo Computazionale
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Coding, informatica, pensiero computazionale e tanto altro.
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} target='_blank' to='https://www.moteefe.com/store/mondo-computazionale?category=all'>
        Shop
      </Button>
      <Button variant='white' target='_blank' as={Link} to='https://www.nebumix.com'>
        Forum
      </Button>
    </Box>
  </>
)
